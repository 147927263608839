.main-page-image {
    display: flex;
    flex-direction: column;
    margin: 0;
    gap: 20px;
    padding-top: 10px;
}

.main-page-image img {
    width: 90%;
    height: auto;
}

.main-page-image h3 {
    margin: 0;
}

@media screen and (max-width: 400px) {
    .main-page-image .manufacturer-logo-title {
        font-size: 18px;
    }
}