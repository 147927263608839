html {
    height: 100%
}

.navbar {
    min-height: 70px;
}

.navbar .nav {
    float: right;
}

.cart, .cart:hover, .cart:active, .cart:visited, .cart:focus {
    color: #777;
    text-decoration: none;
    min-height: 40px;
    min-width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex !important;
    align-items: center;
    -webkit-box-align: center;
}

.cart-title {
    margin-left: 10px;
    color: #000;
}

.cart-icon {
    font-size: 26px;

}

@media screen and (max-width: 768px) {
    .cart-icon {
        font-size: 22px;
    }

    .cart-count {
        vertical-align: top;
    }

    .navbar {
        min-height: 63px;
    }

    .navbar-brand {
        padding-left: 0;
    }
}

.cart-title:hover {
    text-decoration: underline;
    color: #000;
}

.quick-search-form {
    width: 38ch;
}

@media screen and (max-width: 500px) {
    .quick-search-form {
        width: 100%;
    }
    
}

.moto {
    font-size: 12px;
}

body {
    background-color: #fff;
    margin: 0;
    font-family: Trebuchet MS, Arial, Helvetica, sans-serif;
    display: block;
    overflow: auto;
    min-height: 100%;
    position: relative;
    padding: 0 0 170px;
}

footer {
    clear: both;
    height: 170px;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 30px;
    color: #777;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

footer .container-fluid {
    background-color: #f8f8f8;
    border-width: 0 0 1px;
    border-color: #e7e7e7;
    box-shadow: 0 -1px 2px 0 rgba(199, 199, 199, 1);
    padding-top: 10px;
}

footer strong {
    color: #545454;
}

.table > tbody > tr > td, .table > tfoot > tr > td {
    vertical-align: middle;
}

@media screen and (max-width: 768px) {
    table#cart tbody td .form-control {
        width: 20%;
        display: inline !important;
    }

    .actions .btn {
        width: 36%;
        margin: 1.5em 0;
    }

    .actions .btn-info {
        float: left;
    }

    .actions .btn-danger {
        float: right;
    }

    table#cart thead {
        display: none;
    }

    table#cart tbody td {
        display: block;
        padding: .6rem;
        min-width: 320px;
    }

    table#cart tbody tr td:first-child {
        background: #333;
        color: #fff;
    }

    table#cart tbody td:before {
        content: attr(data-th);
        font-weight: bold;
        display: inline-block;
        width: 8rem;
    }


    table#cart tfoot td {
        display: block;
    }

    table#cart tfoot td .btn {
        display: block;
    }

    footer {
        height: 240px;
    }

    body {
        padding: 0 0 240px;
    }
}

.input-group-addon {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    color: #55595c;
    text-align: center;
    background-color: #eceeef;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
}

.input-group-addon, .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
}

.g-d-p-r-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 8px 0 8px 35px;
    background: #e6e6e6;
    box-shadow: 0 0 5px #999;
}

.g-d-p-r-banner-close-button {
    position: absolute;
    top: 50%;
    left: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: none;
    padding: 0;
    background: 0 0;
    outline: 0;
    text-align: inherit;
    font-size: 20px;
    color: #999;
}

.version-info {
    font-size: 80%;
    border-top: 1px solid #eee;
}

label.required:not(.hide-required):after {
    content: "*";
    color: #ff0000;
    font-weight: bold;
}

.manufacturer-logo-title {
    min-height: 52px;
}

.manufacturer-logo {
    min-height: 130px;
}

.img[loading="lazy"] {
    content-visibility: auto;
}

.catalog-quantity {
    width: 85px;
    text-align: right;
}

.catalog-price {
    font-weight: bolder;
}

.catalog-image:hover {
    text-decoration: none;
}

/* Boostrap 3 hack to support flexbox */
.row {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    flex-wrap: wrap !important;
}

.row > [class*='col-'] {
    display: flex !important;
    flex-direction: column !important;
}

.navbar-nav__info {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

@media screen and (max-width: 1194px) {
    .gap-3 {
        gap: 10px;
    }
}

.container-flex-row {
    display: flex;
    flex-direction: row;
}

.gap-1 {
    gap: 10px;
}

.gap-2 {
    gap: 20px;
}

.gap-3 {
    gap: 30px;
}

.fh {
    height: 100%;
}

.ai-center {
    align-items: center;
}

/*Boostrap 3 hack to support flexbox end */
