.container-flex-row {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.fw {
    width: 100%;
}