@import url(./images.css);
@import url(./partials/inputs.css);
@import url(./flex.css);

.catalog-manufacturer-logo {
    height: 34px;
}

.catalog-manufacturer-logo-tiny {
    height: 16px;
}

/*text*/
.text-vcenter {
    vertical-align: middle !important;
}

.text-bold {
    font-weight: 700;
}

.text-h2 {
    font-size: 30px;
}

/*alignment*/
.align-center {
    text-align: center;
}

/*positions*/
.position-fixed {
    position: fixed;
}

/*borders*/
.border-dashed {
    border-style: dashed;
}

.border-danger {
    border-color: #dc3545;
}

.border-2 {
    border-width: 2px;
}

.border-1 {
    border: 1px solid #000;
}

.border-right-1 {
    border-right: 1px solid #000;
}

.border-muted {
    border-color: #ccc;
}

/*padding*/
.p-b-20 {
    padding-bottom: 20px;
}

.pt25 {
    padding-top: 25px;
}

.p16 {
    padding: 16px;
}

.pl0 {
    padding-left: 0;
}

.p0 {
    padding: 0;
}

/*height*/
.h34 {
    max-height: 34px;
}

/*margins*/
.m-l-5 {
    margin-left: 5px;
}
.m-r-5 {
    margin-right: 5px;
}
.mb5 {
    margin-bottom: 5px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb10 {
    margin-bottom: 10px;
}

.mh84 {
    max-height: 84px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}

.mt50 {
    margin-top: 50px;
}

.mt10 {
    margin-top: 10px;
}

.mt5 {
    margin-top: 5px;
}

.mt-0 {
    margin-top: 0;
}

.mt-4 {
    margin-top: 1.5rem;
}

.p-3 {
    padding: 1rem
}

.mt-2 {
    margin-top: .5rem
}

.mr5 {
    margin-right: 5px;
}

.mr-auto {
    margin-right: auto;
}

.p-h-1 {
    padding: 0 1em;
}


@media screen and (max-width: 500px) {
    .atostogos-container {
        flex-direction: column !important;
        padding: 1em;
        align-items: stretch !important;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        order: 2;
    }
}

.atostogos {
    color: red;
    font-size: 16px;
    font-weight: bold;
}

/*display*/
.display-inline {
    display: inline;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

/* badges */
.badge.badge-success {
    background-color: #5cb85c;
    color: #fff;
}

.badge.badge-danger {
    background-color: #d9534f;
    color: #fff;
}

.navbar-nav {
    float: right;
}

.dropdown-toggle::after {
    display: none !important;
}

.home-page-header::before, .home-page-header::after {
    display: none !important;
    width: 100%;
}

.quick-search-form .btn {
    display: flex;
    flex-direction: row;
    gap: 10px;
}